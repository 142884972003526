import { COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

const _padding = 'clamp(30px, 5vw, 80px)';

export const Section = styled.section`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;

    @media ${DEVICE.desktop} { 
        display: block;
        padding-top: ${_padding};
        padding-bottom: ${_padding};    }
`;

export const Title = styled.h1`
        width: 100vw;
    margin-bottom: clamp(10px, 5vw, 50px);

    @media ${DEVICE.desktop} { 
    }

    span {
        color: ${COLOR.primary};

        @media ${DEVICE.desktop} { 
            display: block;
        }
    }
`;

export const WrapperTrainingPeaks = styled.div`
    position: static;
`;

export const Description = styled.div`
    padding-left: 30px;
    padding-right: 30px;

    @media ${DEVICE.desktop} { 
        padding-right: 0;
    }
`;

export const Img = styled.img`
    display: none;
    position: absolute;
    top: 55%;
    left: -25%;
    width: clamp(360px, 68vw, 1030px);
    transform: translateY(-50%);

    @media ${DEVICE.desktop} { 
        display: flex;
    }
`;