import styled from '@emotion/styled';
import { DEVICE } from '../../mediaquery';
import { FAMILY, COLOR, WEIGHT } from '../../vars';

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;

    @media ${DEVICE.desktop} { 
        padding-top: clamp(40px, 10vw, 80px);
        padding-bottom: clamp(40px, 10vw, 150px);
    }
`;

export const Column = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: clamp(40px, 25vw, 100px);
    padding-left: 30px;
    padding-right: 30px;

    @media ${DEVICE.desktop} { 
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }

    + div {
        border-left: 1px solid ${COLOR.greybold};
    }

    > div {
        width: 72vw;

        @media ${DEVICE.desktop} { 
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: auto;
            height: 100%;
        }
    }
` 

export const Title = styled.h2`
    align-self: flex-end;
    margin-bottom: clamp(30px, 10vw, 60px);
    padding-left: clamp(15px, 20vw, 25px);

    @media ${DEVICE.desktop} { 
        padding-right: clamp(200px, 20vw, 500px);
    }

    span {
        color: ${COLOR.primary};
    }
`;

export const List = styled.ul`
    margin-left: 0;
    margin-bottom: clamp(16px, 10vw, 35px);
    padding-left: 18px;
`;

export const ItemTitle = styled.h3`
    font-size: clamp(15px, 7vw, 30px);
    text-align: left;
    margin-bottom: clamp(8px, 5vw, 15px);
`;

export const Price = styled.div`
    position: relative;

    @media ${DEVICE.desktop} { 
        height: clamp(25px, 10vw, 55px);
        margin-top: auto;
    }

    p {
        position: absolute;
        right: 0;
        display: inline-block;
        font-family: ${FAMILY.primary};
        color: ${COLOR.tertiary};

        @media ${DEVICE.desktop} { 
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
    }

    span:first-of-type {
        position: relative;
        z-index: 2;
        font-size: clamp(15px, 10vw, 35px);
        margin-right: clamp(15px, 5vw, 30px);
        padding-top: clamp(2px, 2vw, 4px);
        padding-bottom: clamp(2px, 2vw, 4px);
        padding-left: clamp(5px, 2vw, 10px);
        padding-right: clamp(5px, 2vw, 10px);
        background-color: ${COLOR.primary};
    }

    span:last-child {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: clamp(33px, 16vw, 63px);
        width: max-content;
        font-size: clamp(9px, 5vw, 16px);
        padding-top: clamp(1px, 2vw, 3px);
        padding-bottom: clamp(1px, 2vw, 3px);
        padding-left: clamp(5px, 2vw, 10px);
        padding-right: clamp(5px, 2vw, 10px);
        background-color: ${COLOR.secondary};

        @media ${DEVICE.desktop} { 
            left: clamp(33px, 10vw, 63px);
        }
    }
`;