import React from 'react';
import { Section, Column, Title, List, ItemTitle, Price } from './Tarifas.css';

const Tarifas = () => {
    return (
        <Section id="tarifas">
            <Title>Tarifas <span>elige tu plan</span></Title>
            <div className="container">
                <div className="row">
                    <Column className="col-lg-6">
                        <div>
                            <ItemTitle>Coaching <strong>presencial</strong></ItemTitle>
                            <List>
                                <li> Entrevista </li>
                                <li> Valoración inicial</li>
                                <li> Planificación en función del objetivo </li>
                                <li> Entrenamiento presencial </li>
                            </List>
                            <Price>
                                <p>
                                    <span>40€</span>
                                    <span>x hora</span>
                                </p>
                            </Price>
                        </div>
                    </Column>
                    <Column className="col-lg-6">
                        <div>
                            <ItemTitle>Coaching <strong>on-line</strong></ItemTitle>
                            <List>
                                <li> Entrevista inicial </li>
                                <li>Control y valoración (test de campo específicos)</li>
                                <li> Planificación individualizada </li>
                                <li> Envío semanal del programa de entrenamiento </li>
                                <li> Seguimiento del entrenamiento con <a href="http://www.trainingpeaks.com" target="_blank">Trainningpeaks©</a> </li>
                                <li> Comunicación: correo electrónico y whatsapp </li>
                            </List>
                            <Price>
                                <p>
                                    <span>65€</span>
                                    <span>al mes</span>
                                </p>
                            </Price>
                        </div>
                    </Column>
                </div>
            </div>
        </Section>
    );
}
 
export default Tarifas;