import React, { useState } from 'react';
import ShareHeader from '../Header/ShareHeader/ShareHeader';
import { FooterTag, Row, Nav, NavItem, NavLink, Copyright, ShareWrapper } from './Footer.css.js';
import { scrollToSection } from '../utils/scrollToSection';

const Footer = () => {
    const [is_header, setHeader] = useState(false);

    return (
        <FooterTag>
            <div className="container">
                <Row className="row">
                    <div className="col-md-8">
                        <Nav>
                            <NavItem>
                                <NavLink data-id="home" href="#home" onClick={scrollToSection}>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink data-id="bienvenido" href="#link" onClick={scrollToSection}>Bienvenido</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink data-id="especialidades" href="#link" onClick={scrollToSection}>Especialidades</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink data-id="covid" href="#link" onClick={scrollToSection}>Covid-19</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink data-id="testimonios" href="#link" onClick={scrollToSection}>Testimonios</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink data-id="sobre_mi" href="#link" onClick={scrollToSection}>Sobre mí</NavLink>
                            </NavItem>
                        </Nav>
                        <ShareWrapper>
                            <ShareHeader
                                is_header={false}
                            />
                        </ShareWrapper>
                        <Copyright>
                            <p>©Copyright 2020 MC Training</p>
                        </Copyright>
                    </div>
    
                    
                </Row>
                
            </div>
        </FooterTag>
    );
}

export default Footer;