import { COLOR, WEIGHT } from '../vars';
import { DEVICE } from '../mediaquery';
import styled from '@emotion/styled';
import { rgba } from 'polished';

const _transition = '1s';

export const Section = styled.section`
    position: fixed;
    right: 0;
    bottom: 0;
	z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: clamp(50px, 30vw, 125px);
    height: auto;
    padding-top: .7rem;
    padding-bottom: .7rem;
	background-color: ${COLOR.white};
	box-shadow: -4px -3px 5px 0px ${rgba(COLOR.black, .09)};

    @media ${DEVICE.desktop} {
        width: clamp(50px, 30vw, 125px);
        height: clamp(75px, 30vw, 170px);
    }

    &.active {
        background-color: ${COLOR.white};
        transition: bottom ${_transition};

        .open > p {
            color: ${COLOR.black};
        }

        .contacta p {
            color: ${COLOR.black};
        }
    
        .arrow {
            transform: scaleY(1);

            &--up {
                display: none;
            }

            &--down {
                display: block;
            }
        }
    }

    .contacta {
        cursor: pointer;
        color: ${COLOR.black};

        @media ${DEVICE.portable} {
            font-size: 1.4rem;
        }
    }

    .link {
        display: flex;
        justify-content: center;
    }

    .logo {
        display: flex;
        justify-content: center;
        width: 70%;
        height: auto;
    }
`

export const Contacta = styled.h3`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLOR.white};
    text-transform: uppercase;
    margin-bottom: clamp(4px, 2vw, 20px);

    p {
        color: ${COLOR.white};
        margin-bottom: 0;
        padding-top: clamp(5px, 3vw, 10px);
        padding-bottom: clamp(5px, 3vw, 10px);

        strong {
            font-size: clamp(10px, 3vw, 15px);
        }
    }

    .arrow {
        width: 15px;
        height: 15px;
        margin-left: clamp(4px, 1vw, 12px);
        transform: scaleY(-1);

        @media ${DEVICE.desktop} {
            width: clamp(10px, 5vw, 20px);
            height: clamp(10px, 5vw, 20px);
        }

        &--up {
            display: block;
        }

        &--down {
            display: none;
        }
    }
`

export const Wrapper = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const Photo = styled.div`
    overflow: hidden;
    width: clamp(50px, 5vw, 100px);
    height: clamp(50px, 5vw, 100px);
    margin-bottom: clamp(6px, 2vw, 12px);
    border-radius: 100%;

    img { 
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`

export const Name = styled.p`
    font-size: clamp(12px, 3vw, 18px);
    text-align: center;
    font-weight: ${WEIGHT.bold};
    line-height: clamp(12px, 2vw, 17px);
    margin-bottom: clamp(2px, 2vw, 5px);
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: clamp(12px, 3vw, 16px);
    line-height: clamp(18px, 2vw, 14px);
`

export const Colegiado = styled.p`
    margin-bottom: clamp(2.5px, 3vw, 5px);
`

export const Tel = styled.p`
    margin-top: clamp(10px, 3vw, 20px);

    strong {
        font-size: clamp(18px, 5vw, 25px);
    }

    img {
        width: clamp(20px, 3vw, 80px);
        height: clamp(20px, 3vw, 80px);
    }
`