import { COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

export const Section = styled.section`
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${COLOR.greylight};

    @media ${DEVICE.desktop} { 
        padding-top: clamp(40px, 10vw, 80px);
        padding-bottom: clamp(40px, 10vw, 150px);
    }
`;

export const Container = styled.div`
    position: static;

    > .row div:first-of-type {
        padding-right: 45px;
        padding-right: 3.5vw;
    }

    > .row > div {
        position: static;
    }
`;

export const Description = styled.div`
    padding-left: clamp(35px, 10vw, 75px);
`;

export const Title = styled.h2`
    margin-bottom: clamp(10px, 7vw, 33px);
    padding-left: clamp(35px, 10vw, 75px);

    span {
        color: ${COLOR.primary}
    }
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media ${DEVICE.desktop} { 
        position: absolute;
        top: 0;
        right: 0;
        width: 41vw;
    }
`;