import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Title, List, Wrapper, WrapperText, QuoteLeft, QuoteRight, Photo, ListName, ListStars, ListSport, Declaration } from "./Testimonials.css.js";

const Testimonials = () => {

    const images = useStaticQuery(graphql`
        query {
            testimonial01: file(relativePath: {eq: "testimonial--01.jpg"}) {
                publicURL
            },
            testimonial02: file(relativePath: {eq: "testimonial--02.jpg"}) {
                publicURL
            },
            testimonial03: file(relativePath: {eq: "testimonial--03.jpg"}) {
                publicURL
            },
            testimonial04: file(relativePath: {eq: "img-carousel-01.jpg"}) {
                publicURL
            },
            star: file(relativePath: {eq: "star.svg"}) {
                publicURL
            },
            quote: file(relativePath: {eq: "quote.svg"}) {
                publicURL
            }
        }
    `);

    const testimonialitems = [
        {
            image: images.testimonial01.publicURL,
            name: 'Isra Vinardell',
            title: 'Preparación para competición',
            description: 'Necesitaba una planificación precompetición bien estructurada. Los resultados fueron espectaculares, pude llegar a cumplir todos los objetivos de la temporada.',
            sport: 'Ciclismo'
        },
        {
            image: images.testimonial02.publicURL,
            name: 'Hèctor Pascual',
            title: 'Adaptación entrenamiento',
            description: 'Debido al COVID Marc adaptó las rutinas de entrenamiento para mar abierto. Qué mejor lugar para entrenar que en el propio medio! No he vuelto a la piscina.',
            sport: 'Natación aguas abiertas'
        },
        {
            image: images.testimonial03.publicURL,
            name: 'Dani Tomas',
            title: 'Testimonial 3',
            description: 'Entrenaba sin ningún tipo de criterio. Siguiendo un plan de entreno, tengo un control de las cargas, mejorando notablemente los rankings en carrera.',
            sport: 'Trail running'
        }
    ];

    return (
        <Section id="testimonios">
                <Title><span>Opinión</span> de clientes</Title>
            <div className="container">
                <List className="row">
                    {
                        testimonialitems.map((item, i) => {
                            return (
                                <li key={i} className="col-lg-4">
                                    <Wrapper>
                                        <Photo className="photo">
                                            <img src={item.image} alt="" />
                                        </Photo>			
                                        <WrapperText>
                                            <Declaration>
                                                <QuoteLeft>
                                                    <img src={images.quote.publicURL} alt="" />
                                                </QuoteLeft>
                                                {item.description}
                                                <QuoteRight>
                                                    <img src={images.quote.publicURL} alt="" />
                                                </QuoteRight>
                                            </Declaration>
                                            <ListStars>
                                                <li><img src={images.star.publicURL} alt="" /></li>
                                                <li><img src={images.star.publicURL} alt="" /></li>
                                                <li><img src={images.star.publicURL} alt="" /></li>
                                                <li><img src={images.star.publicURL} alt="" /></li>
                                            </ListStars>
                                            <ListName>{item.name}</ListName>
                                            <ListSport>{item.sport}</ListSport>
                                        </WrapperText>
                                        
                                    </Wrapper>
                                </li>
                            );
                        })
                    }
                </List>
            </div>
        </Section>
    );
}
 
export default Testimonials;