import styled from '@emotion/styled';
import { FAMILY, COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import { css } from '@emotion/react';

const _quotesProps = css`
    position: absolute;

    img {
        width: 10vw;
        max-width: 20px;
        height: 10vw;
        max-height: 20px;
    }
`
export const Section = styled.section`
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color:${COLOR.greylight};

    @media ${DEVICE.desktop} { 
        padding-top: clamp(40px, 10vw, 80px);
        padding-bottom: clamp(40px, 10vw, 80px);
    }

    > .container {
        position: relative;
        z-index: 2;
        height: 100%;
        padding-left: 30px;
        padding-right: 30px;

        @media ${DEVICE.desktop} { 
            padding-left: 15px;
            padding-right: 15px;
        }
    }	
`;

export const Title = styled.h2`
    text-align: left;
    color: ${COLOR.primary};
    margin-bottom: clamp(30px, 10vw, 69px);
    padding-left: clamp(35px, 10vw, 75px);

    @media ${DEVICE.desktop} { 
        width: auto;
        text-align: right;
    }

    span {
        color: ${COLOR.tertiary};
    }
`;

export const List = styled.ul`
    list-style-type: none;
    display: flex;
    padding: 0;

    > li {
        margin-bottom: clamp(25px, 10vw, 50px);
    }
`;

export const Wrapper = styled.div`
    cursor: pointer;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: clamp(90px, 30vw, 200px);
    padding-bottom: clamp(10px, 5vw, 20px); 
    border: 1px solid ${COLOR.greymedium};
    background-color: ${COLOR.white};

    &:hover {

        .photo {
            top: clamp(-15px, -10vw, -30px);
        }
    }
`;

export const Photo = styled.div`
    overflow: hidden;
    position: absolute;
    top: clamp(-20px, -5vw, -40px);
    width: 70vw;
    height: 30vw;
    transition: all .25s;

    @media ${DEVICE.desktop} { 
        width: 30vw;
        max-width: 280px;
        height: 12vw;
        max-height: 220px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export const WrapperText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: clamp(35px, 5vw, 50px);
    padding-right: clamp(35px, 5vw, 50px);
`;

export const Declaration = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${COLOR.secondary};
    text-align: center;
    line-height: 20px;
    margin-bottom: 0;
`;

export const QuoteLeft = styled.div`
    ${_quotesProps}
    top: -10px;
    left: -24px;

    img {
        transform: Scale(-1);
    }
`;

export const QuoteRight = styled.div`
    ${_quotesProps}
    bottom: -10px;
    right: -24px;
`;

export const ListStars = styled.ul`
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-top: clamp(5px, 3vw, 10px);
    margin-bottom: clamp(6px, 3vw, 15px);
    padding-left: 0;
`;

export const ListName = styled.h3`
    display: inline-block;
    font-size: clamp(1rem, 5vw, 1.75rem);
    color: ${COLOR.tertiary};
    text-align: center;
    margin-bottom: 0;
    padding-top: clamp(2px, 5vw, 5px);
    padding-bottom: clamp(2px, 5vw, 5px);
    padding-left: clamp(5px, 5vw, 10px);
    padding-right: clamp(5px, 5vw, 10px);
    background-color: ${COLOR.secondary};
`;

export const ListSport = styled.p`
    font-family: ${FAMILY.primary};
    display: inline-block;
    font-size: clamp(14px, 5vw, 16px);
    color: ${COLOR.secondary};
    text-align: center;
    line-height: 20px;
    padding-top: clamp(2px, 5vw, 5px);
    padding-bottom: clamp(2px, 5vw, 5px);
    padding-left: clamp(5px, 5vw, 10px);
    padding-right: clamp(5px, 5vw, 10px);
    background-color: ${COLOR.greymedium};
`;


