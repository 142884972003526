import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Container, Title, Img } from './OnLine.css.js';

const OnLine = () => {

    const QUERY = useStaticQuery(graphql`
        query {
            image: file(relativePath: {eq: "img-online.png"}) {
                publicURL
            }
        }
    `);

    return (
        <Section id="covid">
            <Container className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <Img src={QUERY.image.publicURL} alt="" />
                    </div>
                    <div className="col-lg-7">
                        <Title>Entrenamiento <span>on-line</span></Title>
                        <p>Somos especialistas en planificar tus entrenamientos <strong>on-line</strong>. Tenemos una amplia experiencia desarrollando planes a distancia que puedas seguir dónde y cuando quieras.</p> 
                        <p>Tras una entrevista telefónica o presencial, creamos un plan adaptado totalmente a tus metas y estado físico actual, con controles semanales y análisis estadísticos del progreso a través de la premiada herramienta <strong>TraininPeaks</strong>.</p>
                        <p>A más a más, nos adaptamos a tí tanto si es para hacer clases presenciales por videoconferencia o siguiendo las pautas e instrucciones del plan que creemos para tí</p>
                        <p>Siempre hemos creído que la persistencia es la clave para el progreso del atleta, y la distancia o presencialidad no debería ser un inconveniente para llevar a cabo tus entrenamientos.</p>
                    </div>
                </div>
            </Container>
        </Section>
    );
}
 
export default OnLine;