import React from 'react';
import { DEVICE } from '../../mediaquery';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';

const StyledLink = styled.a`
    display: block;
    width: 190px;
    max-width: 190px;
    height: auto;
    margin-top: -3px;

    @media ${DEVICE.desktop} {
        width: 20vw;
    } 
`;

const BrandNavbar = () => {

    const { logo } = useStaticQuery(graphql `
        query {
            logo: file(relativePath: {eq: "logo-mctraining.svg"}) {
                publicURL
            }
        }
    `);

    return (
        <>
            <StyledLink href="#">
                <img src={ logo.publicURL } className="img-fluid" alt="Mc Training" />
            </StyledLink>
        </>
    );
}
 
export default BrandNavbar;