import React, { useState, useEffect } from 'react';
import { COLOR, HEIGHT } from '../vars';
import { Media } from "../breakpoints";
import { DEVICE } from '../mediaquery';
import BrandNavbar from './BrandNavbar/BrandNavbar';
import NavbarHeader from './NavbarHeader/NavbarHeader';
import ShareHeader from './ShareHeader/ShareHeader';
import styled from '@emotion/styled';
import { rgba } from 'polished';

const HeaderStyled = styled.header`
    position: fixed;
    top:0;
    left:0;
    z-index: 99 !important;

    display: flex;
    align-items: center;
    width: 100%;
    height: 72px;
    max-height: ${HEIGHT.header};
    
    background-color: ${rgba(COLOR.secondary, 0)};
	transition: all .25s;

    @media ${DEVICE.desktop} { 
        height: 10vw;
    }

	&.scrolled {
		background-color: ${rgba(COLOR.secondary, .9)};

        @media ${DEVICE.desktop} { 
            max-height: ${HEIGHT.headersticky};
        }
	}
`

const Container = styled.div`

    @media ${DEVICE.portable} { 
        max-width: none;
    }

    > .row {
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
`

const Header = () => {
    const [is_header, setHeader] = useState(false);
    
    useEffect(() => {
        const _body = document.body;

        const addClassWhenScroll = () => {
            const _header = document.querySelector('header');
            let scroll = window.scrollY;

            if (scroll >= 50) {
                _body.classList.add('scrolled');
                _header.classList.add('scrolled');

            }
            else {
                _body.classList.remove('scrolled');
                _header.classList.remove('scrolled');
            }
        }

        window.addEventListener('scroll', addClassWhenScroll);
    }, [])

    return (
        <HeaderStyled>
            <Container className="container">
                <div className="row">
                    <BrandNavbar />
                    <NavbarHeader />
                    <Media at="lg">
                        <ShareHeader
                            is_header={true}
                        />
                    </Media>
                </div>
            </Container>
        </HeaderStyled>
    );
}

export default Header;