import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { List, ListItem, ListAnchor, ListImage } from './ShareHeader.css.js';

const ShareHeader = ({is_header}) => {

    const icons = useStaticQuery(graphql`
        query {
            instagram: file(relativePath: {eq: "ico-instagram--white.svg"}) {
                publicURL
            },
            twitter: file(relativePath: {eq: "ico-twitter--white.svg"}) {
                publicURL
            },
            youtube: file(relativePath: {eq: "ico-youtube--white.svg"}) {
                publicURL
            }
        }
    `);

    return (
        <List className={ is_header ? 'header' : 'footer' }>
            <ListItem>
                <ListAnchor href="http://www.instagram.com/coach_mctraining" target="_blank">
                    <ListImage className={ is_header ? 'header' : 'footer' } src={icons.instagram.publicURL} alt="Instagram" />
                </ListAnchor>
            </ListItem>
            <ListItem>
                <ListAnchor href="http://www.twitter.com/CoachMctraining" target="_blank">
                    <ListImage className={ is_header ? 'header' : 'footer' } src={icons.twitter.publicURL} alt="Twitter" />
                </ListAnchor>
            </ListItem>
            <ListItem>
                <ListAnchor href="https://www.youtube.com/channel/UCRDI6_X7rOeNSuCc946LlGg" target="_blank">
                    <ListImage className={ is_header ? 'header' : 'footer' } src={icons.youtube.publicURL} alt="Youtube" />
                </ListAnchor>
            </ListItem>
        </List>
    );
}

export default ShareHeader;