import { COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

export const Section = styled.section`
    overflow: hidden;
    position: relative;
    padding-top: clamp(40px, 10vw, 80px);
    padding-bottom: 30px;

    @media ${DEVICE.desktop} { 
        padding-bottom: clamp(40px, 10vw, 80px);
    }
`;

export const Container = styled.div`
    position: static;
    padding-left: 30px;
    padding-right: 30px;

    @media ${DEVICE.desktop} { 
        padding-left: 15px;
        padding-right: 15px; 
    }

    > .row {
        flex-direction: column-reverse;

        @media ${DEVICE.desktop} { 
            flex-direction: row;
        }
    }

    > .row div:first-of-type {
        padding-right: clamp(22px, 5vw, 45px);
    }

    > .row > div {
        position: static;
    }
`;

export const Title = styled.h2`
    width: 100vw;
    margin-bottom: clamp(10px, 5vw, 40px);

    @media ${DEVICE.desktop} { 
        flex-direction: row;
        margin-left: 0;
    }

    span {
        display: block;
        color: ${COLOR.primary};

        @media ${DEVICE.desktop} { 
            display: inline-block;
        }
    }
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media ${DEVICE.desktop} { 
        object-fit: contain;
        position: absolute;
        top: clamp(0.1vw, 0.1vw, 1vw);
        right: clamp(58vw, 30vw, 77vw);
        width: clamp(182px, 100vw, 1000px);
    }
`;
