import { FAMILY, COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Container = styled.div`

    .carousel {
        display: flex;
        height: 100vh;

        &-item {
            position: relative;
            height: 100%;
        }

        &-caption {
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            width: 70vw;
            padding-left: 50px;
            padding-right: 50px;
            padding-left: 1vw;
            padding-right: 1vw;
            transform: translate(-50%, -50%);

            @media ${DEVICE.desktop} {
                width: clamp(300px, 76vw, 800px);
            }
        }
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${rgba(COLOR.secondary, .75)};
    }
`;

export const Title = styled.p`
    font-family: ${FAMILY.primary};
    font-size: clamp(30px, 10vw, 60px);
    color: ${COLOR.primary};
    text-transform: uppercase;
    line-height: clamp(32px, 10vw, 65px);
    padding-top: clamp(5px, 5vw, 10px);
    padding-bottom: clamp(5px, 5vw, 10px);
    padding-left: clamp(5px, 5vw, 15px);
    padding-right: clamp(5px, 5vw, 15px);
    background-color: ${rgba(COLOR.black, .5)}
`

export const Description = styled.p`
    font-size: clamp(14px, 1.5vw, 18px);
    line-height: clamp(18px, 2vw, 22px);
    margin-bottom: clamp(18px, 5vw, 22px);
`

export const Button = styled.span`
    color: ${COLOR.tertiary};
    background-color: ${COLOR.secondary};
`

export const Url = styled.a`
    color: ${COLOR.tertiary};
    text-decoration: none;
    background-color: ${COLOR.secondary};
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;