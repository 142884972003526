import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Title, List, ItemList, Anchor, ItemTitle, ItemDescription, Img } from './Sports.css.js';

const Sports = () => {

    const images = useStaticQuery(graphql`
        query {
            svgfitness: file(relativePath: {eq: "icon-fitness.svg"}) {
                publicURL
            },
            svgrecuperacion: file(relativePath: {eq: "icon-recuperacion.svg"}) {
                publicURL
            },
            svgtriathlon: file(relativePath: {eq: "icon-cyclyst.svg"}) {
                publicURL
            },
            svgrunning: file(relativePath: {eq: "icon-running.svg"}) {
                publicURL
            },
            svgcyclyst: file(relativePath: {eq: "icon-cyclyst.svg"}) {
                publicURL
            },
            svgswim: file(relativePath: {eq: "icon-swim.svg"}) {
                publicURL
            },
        }
    `);

    const sportitems = [
        {
            image: images.svgfitness.publicURL,
            title: 'Fitness',
            description: 'Preparación física para conseguir tono muscular tano en outdoor como indoor'
        },
        {
            image: images.svgrecuperacion.publicURL,
            title: 'Recuperación post lesión',
            description: 'Recuperar la fuerza y funcionalidad de las partes afectadas'
        },
        {
            image: images.svgtriathlon.publicURL,
            title: 'Triathlon',
            description: 'Entrenamiento de las tres disciplinas enfocadas a la competición'
        },
        {
            image: images.svgrunning.publicURL,
            title: 'Running',
            description: 'Entrenamiento y técnica de carrera para las diferentes modalidades y distancias'
        },
        {
            image: images.svgcyclyst.publicURL,
            title: 'Ciclismo',
            description: 'Entrenamiento tanto para la modalidad de carretera o montaña'
        },
        {
            image: images.svgswim.publicURL,
            title: 'Natación',
            description: 'Entrenamiento y técnica enfocado tanto a piscina como en aguas abiertas'
        }
    ];

    const preventOpenLink = e => {
        e.preventDefault();
    }

    return (
        <Section id="especialidades">
            
            <Title>Nuestras especialidades <span>deportivas</span></Title>
            <div className="container">
                <List className="row">
                    {
                        sportitems.map((item, i) => {
    
                            return (
                                <ItemList key={i} className="col-lg-4">
                                    <Anchor className="button" href="#" onClick={preventOpenLink}>
                                        <Img src={item.image} alt="" />
                                        <ItemTitle>{item.title}</ItemTitle>
                                        <ItemDescription>{item.description}</ItemDescription>
                                    </Anchor>
                                </ItemList>
                            );
                        })
                    }
                </List>
            </div>
                        
        </Section>
    );
}
 
export default Sports;