import { createMedia } from "@artsy/fresnel"
import { SIZE } from './mediaquery';

const ExampleAppMedia = createMedia({
    breakpoints: {
        xs: 0,
        md: `${SIZE.md}`,
        lg: `${SIZE.lg}`
    },
})

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = ExampleAppMedia