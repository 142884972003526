import { FAMILY, COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

const time = '.6s';

export const Section = styled.section`
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color:${COLOR.greylight};

    @media ${DEVICE.desktop} { 
        padding-top: clamp(40px, 10vw, 80px);
        padding-bottom: clamp(40px, 10vw, 80px);
    }

    > .container {
        position: relative;
        z-index: 2;
        height: 100%;
        padding-left: 30px;
        padding-right: 30px;

        @media ${DEVICE.desktop} { 
            padding-left: 15px;
            padding-right: 15px;
        }
    }	
`;

export const Container = styled.div`
    position: static;

    > .row div:first-of-type {
        padding-right: 45px;
        padding-right: 3.5vw;
    }

    > .row > div {
        position: static;
    }
`;

export const Title = styled.h2`
    text-align: left;
    color: ${COLOR.primary};
    margin-bottom: clamp(30px, 10vw, 69px);
    padding-left: clamp(35px, 10vw, 75px);

    @media ${DEVICE.desktop} { 
        width: auto;
        text-align: right;
    }

    span {
        color: ${COLOR.tertiary};
    }
`;

export const List = styled.ul`
    list-style-type: none;
    display: flex;
    padding: 0;

    > li {
        margin-bottom: clamp(25px, 10vw, 50px);
    }
`;

export const Wrapper = styled.div`
    cursor: pointer;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: clamp(25px, 30vw, 131px);
    padding-bottom: 34px;
    padding-left: clamp(15px, 2vw, 20px);
    padding-right: clamp(15px, 2vw, 20px);
    border: 1px solid ${COLOR.greymedium};
    background-color: ${COLOR.white};

    &:hover {

        .photo {
            top: clamp(-15px, -10vw, -30px);
        }

        .showMore {
            right: -10px;
        }
    }
`;

export const Photo = styled.div`
    overflow: hidden;
    position: absolute;
    top: clamp(-20px, -5vw, -40px);
    width: 70vw;
    height: 30vw;
    transition: all .25s;

    @media ${DEVICE.desktop} { 
        width: 30vw;
        max-width: 290px;
        height: 12vw;
        max-height: 130px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export const ListItemTitle = styled.h3`
    font-size: clamp(1.1rem, 5vw, 1.45rem);
    margin-bottom: clamp(.5rem, 3vw, 1.45rem);
`;

export const ListItemDescription = styled.p`
    font-size: clamp(.8rem, 3vw, 1rem);
    line-height: clamp(0.7rem, 5vw, 1.4rem);
`;

export const ShowMore = styled.p`
    position: absolute;
    bottom: -5px;
    right: -5px;
    font-family: ${FAMILY.primary};
    display: inline-block;
    font-size: clamp(14px, 5vw, 16px);
    color: ${COLOR.white};
    text-align: center;
    line-height: 20px;
    padding-top: clamp(2px, 5vw, 5px);
    padding-bottom: clamp(2px, 5vw, 5px);
    padding-left: clamp(5px, 5vw, 10px);
    padding-right: clamp(10px, 5vw, 20px);
    background-color: ${COLOR.black};
    transition: all .25s;
`;