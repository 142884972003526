import { FAMILY, COLOR, ZINDEX } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';
import { darken } from 'polished';

export const Item = styled.div`
    position: relative;
    z-index: ${ZINDEX.base};

    @media ${DEVICE.portable} { 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }

    + div {

        @media ${DEVICE.portable} { 
            margin-top: 20px;
        }
    }

    &.active::before {
        // linea
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: calc(100% - 26px);
        height: 3px;
        background-color: ${COLOR.primary};
    }
`

export const Link = styled.a`
    display: block;
    height: 100%;
    font-family: ${FAMILY.primary};
    font-size: clamp(21px, 2vw, 22px);
    color: ${COLOR.tertiary};
    line-height: 26px;
    padding-left: clamp(8px, 1vw, 13px);
    padding-right: clamp(8px, 1vw, 13px);

    &::before {
        top: 0;
        transform-origin: left center;
    }

    &::after {
        top: 50%;
        transform-origin: right center;
    }

    &:hover {
        color: ${COLOR.tertiary};
        text-decoration: none;

        &::before, &:after {
            transform: scaleX(1);
        }
    }

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: ${ZINDEX.down};
        left: 0;
        width: 100%;
        height: 50%;
        background-color: ${COLOR.primary};
        transform: scaleX(0);
        transition: transform .25s ease-in;
    }
`

export const NavStyled = styled.div`
    display: inline-flex;
    padding-top: 50px;

    @media ${DEVICE.desktop} {
        padding-top: 0;
    }
`

export const NavbarCollapseWrapper = styled.div`

    .navbar-collapse {

        @media ${DEVICE.portable} { 
            position: fixed;
            top: 72px;
            right: 0;
            width: 100vw;
            height: calc(100vh - 77px);
            background-color: ${COLOR.secondary};
        }
    }
`

export const NavbarToggleWrapper = styled.div`
    
    .navbar-toggler {
        padding: 5px;
        background-color: ${darken(.8, COLOR.tertiary)};

        @media ${DEVICE.portable} { 
            position: fixed;
            top: 15px;
            right: 15px;
        }
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

        span:last-child {
            margin-bottom: 0;
        }
    }
`