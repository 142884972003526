import { COLOR } from '../../vars';
import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

const time = '.6s';

export const Section = styled.section`
    position: relative;
    padding-top: clamp(30px, 5vw, 80px);
    padding-bottom: 15px;
    background-color: ${COLOR.greylight};

    @media ${DEVICE.desktop} { 
        padding-bottom: clamp(40px, 10vw, 80px);
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;

        @media ${DEVICE.desktop} { 
            padding-left: 15px;
            padding-right: 15px;
        }
    
        > .row > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

export const Title = styled.h2`
    margin-bottom: clamp(20px, 4vw, 40px);
    padding-left: 30px;
    padding-right: clamp(35px, 13vw, 95px);

    @media ${DEVICE.desktop} { 
        padding-left: clamp(35px, 10vw, 75px);
    }

    span {
        display: block;
        color: ${COLOR.primary};

        @media ${DEVICE.desktop} {
            display: inline-block; 
        }
    }
`;

export const List = styled.ul`
    list-style: none;
    padding: 0;
`;

export const ItemList = styled.li`
    cursor: pointer;
    position: relative;
    height: 245px;
    height: 35vw;
    max-height: 245px;
    margin-bottom: 20px;

    @media ${DEVICE.desktop} { 
        margin-bottom: 30px;
    }
`;

export const Anchor = styled.a`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid ${COLOR.greymedium};
    background-color: ${COLOR.white};

    transition: all ${time};

    &:hover {
        text-decoration: none;
        background-color: ${COLOR.secondary};

        img {
            opacity: 0;
        }

        h3 {
            opacity: 0;
        }

        p {
            opacity: 1;
            top: 50%;
        }
    }
`;

export const Img = styled.img`
    width: 10vw;
    max-width: 90px;
    height: auto;
    margin-bottom: clamp(10px, 3vw, 20px);
    transition: opacity ${time};

    @media ${DEVICE.desktop} { 
        width: 7vw;
        max-width: 90px;
    }
`;

export const ItemTitle = styled.h3`
    font-size: clamp(10px, 56vw, 25px);
    color: ${COLOR.blackmedium};
    line-height: clamp(10px, 6vw, 25px);
    text-align: center;
    transition: opacity ${time};
`;

export const ItemDescription = styled.p`
    opacity: 0;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    color: ${COLOR.white};
    text-align: center;
    line-height: clamp(14px, 5vw, 24px);
    transition: all ${time};
`;




