import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Mailto from 'react-protected-mailto'
import { Section, Contacta, Wrapper, Photo, Name, Info, Colegiado, Tel } from './Contact.css.js';

const Contact = () => {

    const images = useStaticQuery(graphql`
        query {
            marc: file(relativePath: {eq: "marc.png"}) {
                publicURL
            },
            arrowdown: file(relativePath: {eq: "arrow--down.svg"}) {
                publicURL
            },
            arrowup: file(relativePath: {eq: "arrow--up.svg"}) {
                publicURL
            },
            whats: file(relativePath: {eq: "logo-whats.png"}) {
                publicURL
            },
            gmail: file(relativePath: {eq: "logo-gmail.png"}) {
                publicURL
            }
        }
    `);

    const showContact = () => {
        const _layer = document.querySelector('#contact');
        const _bottom = _layer.hasAttribute('data-bottom') ? _layer.getAttribute('data-bottom') : window.getComputedStyle(_layer).bottom;
        _layer.setAttribute('data-bottom', _bottom);        
        _layer.style.bottom = 0;
        _layer.classList.add('active')
    }

    const hideContact = () => {
        const _layer = document.querySelector('#contact');
        const _textContacta = document.querySelector('#contact .contacta')
        const _heightLayer = _layer.offsetHeight;
        const _heightText = _textContacta.offsetHeight;
        const ww = window.innerWidth;
        const _calculateHidedPosition = ww > 1024 ? -_heightLayer + _heightText + 20 : -112;
        const _bottom = _layer.hasAttribute('data-bottom') ? _layer.getAttribute('data-bottom') : _calculateHidedPosition; 
        _layer.style.bottom = _bottom +'px';
        _layer.setAttribute('data-bottom', _bottom);
        _layer.classList.remove('active');
    }

    const toggleContact = () => {
        const _layer = document.querySelector('#contact');
        _layer.classList.contains('active') ? hideContact() : showContact();
    }

    const reportWindowSize = () => {
        console.log('resize')
        const _layer = document.querySelector('#contact');

        if (_layer.hasAttribute('data-bottom')) {
            _layer.removeAttribute('data-bottom');

            if (!_layer.classList.contains('active')) {
                hideContact();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', reportWindowSize);
        window.addEventListener('scroll', hideContact);
        hideContact();
    }, []);

    return (
        <Section id="contact">
            <h3 className="title contacta" onClick={toggleContact}>Contacta</h3>
            <a className="link" href="https://wa.me/+34645893152?text=Hola!%20Me%20interesaría%20recibir%20información%20sobre%20los%20entrenamientos">                
                <img className="logo img-responsive" src={images.whats.publicURL} alt="" />
            </a>        
            <a className="link" href="mailto:info@coachmctraining.com">
                <img className="logo img-responsive" src={images.gmail.publicURL} alt="" />
            </a>
        </Section>
    );
}
 
export default Contact;