import styled from '@emotion/styled';
import { DEVICE } from '../mediaquery';
import { COLOR, WEIGHT } from '../vars';

const padding_x = '8px';

export const FooterTag = styled.footer`
    background-color: ${COLOR.secondary};

    > .container {
        padding-left: 30px;
        padding-right: 30px;

        @media ${DEVICE.desktop} {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;

    @media ${DEVICE.desktop} {
        //height: 30vw;
        //max-height: 90px;
    }

    > div {
        display: flex;
        flex-direction: column;
    }
`;

export const Nav = styled.nav`
    display: inline-flex;
    flex-direction: column;
    margin: 0 -${padding_x};

    @media ${DEVICE.desktop} {
        flex-direction: row;
    }
`;

export const NavItem = styled.div`
    position: relative;
    z-index: 1;

    + div {
        margin-top: clamp(10px, 5vw, 17px);

        @media ${DEVICE.desktop} {
            margin-top: 0;
        }
    
        &::before {

            @media ${DEVICE.desktop} {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 10px;
                height: .7vw;
                max-height: 10px;
                background-color: ${COLOR.white};
                transform: translateY(-50%);
            }
        }
    }
`;

export const NavLink = styled.a`
    display: block;
    height: 100%;
    color: ${COLOR.tertiary};
    font-size: clamp(10px, 5vw, 12px);
    font-weight: ${WEIGHT.bold};
    text-transform: uppercase;
    line-height: 12px;
    line-height: .8vw;
    padding-left: ${padding_x};
    padding-right: ${padding_x};
    padding-left: .5vw;
    padding-right: .5vw;

    @media ${DEVICE.desktop} {
        padding-left: 5px;
        padding-right: 5px;
        padding-left: .5vw;
        padding-right: .5vw;
    }

    &:hover {
        color: ${COLOR.tertiary};
    }
`;

export const Copyright = styled.div`

    p {
        font-size: 11px;
        font-size: .8vw;
        color: ${COLOR.white};
		font-weight: ${WEIGHT.normal};
        line-height: 12px;
        line-height: .8vw;
        margin-top: 6px;
        margin-bottom: 0;
	}
`;

export const ShareWrapper = styled.div`
    display: inline-flex;
    justify-content: flex-start;
`;