import React from 'react';
import { Media } from "../../breakpoints";
import Navbar from 'react-bootstrap/Navbar';
import { Item, Link, NavStyled, NavbarCollapseWrapper, NavbarToggleWrapper } from './NavbarHeader.css.js';
import ShareHeader from '../ShareHeader/ShareHeader';
import { scrollToSection } from '../../utils/scrollToSection';

const NavbarHeader = () => {    

    const closeMenuMobileOnClick = () => {
        const _togglerButton = document.querySelector('.navbar-toggler');
        const _isVisible = getComputedStyle(_togglerButton).display

        if (_isVisible == 'none') return;

        _togglerButton.click()
    }

    const clickEvent = (e) => {
        closeMenuMobileOnClick(e)
        scrollToSection(e)
    }

    return (
        <>
            <Navbar expand="lg">
                <NavbarToggleWrapper>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </NavbarToggleWrapper>
                <NavbarCollapseWrapper>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <NavStyled className="navbar-nav">
                            <Item>
                                <Link 
                                    data-id="home" 
                                    href="#" 
                                    onClick={clickEvent}
                                >Home</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="bienvenido"
                                    href="#" 
                                    onClick={clickEvent}
                                >Bienvenido</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="especialidades"
                                    href="#" 
                                    onClick={clickEvent}
                                >Especialidades</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="covid"
                                    href="#" 
                                    onClick={clickEvent}
                                >On-Line</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="testimonios"
                                    href="#" 
                                    onClick={clickEvent}
                                >Testimonios</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="tarifas"
                                    href="#" 
                                    onClick={clickEvent}
                                >Tarifas</Link>
                            </Item>
                            <Item>
                                <Link 
                                    data-id="sobre_mi"
                                    href="#" 
                                    onClick={clickEvent}
                                >Sobre mí</Link>
                            </Item>
                        </NavStyled>
                        <Media at="xs">
                            <ShareHeader />
                        </Media>
                    </Navbar.Collapse>
                </NavbarCollapseWrapper>
            </Navbar>
        </>
    );
}

export default NavbarHeader;