import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Title, Description, WrapperTrainingPeaks, Img } from './Welcome.css';

const Welcome = () => {

    const images = useStaticQuery(graphql`
        query {
            imgtrainingpeaks: file(relativePath: {eq: "trainingpeaks-dashboard--02.jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <Section id="bienvenido">
            <div className="container-fluid">
                <div className="row">
                    <WrapperTrainingPeaks className="col-lg-5">
                        <Img src={images.imgtrainingpeaks.publicURL} alt="Training Peaks" />
                    </WrapperTrainingPeaks>
                    <Description className="col-lg-6">
                        <Title>Bienvenido a la web de <span>Coach McTraining</span></Title>
                        <p> Estudiamos y analizamos cada atleta en particular diseñando un planning de <strong>entrenamiento personalizado</strong> con <strong>objetivos a corto y largo plazo</strong>, adaptándolo a su día a día, ya sea <strong>on-line o presencial</strong>. </p>
                        <p> Seguimos la evolución y progreso mediante <strong>software</strong> donde el atleta podrá analizar su día a día del rendimento en metas establecidas. Uno de nuestros objetivos no es entrenar más, sino entrenar la <strong>eficiencia</strong>, igual que un atleta <strong>profesional</strong>. </p>
                        <p>Nuestros entrenamientos estratégicos te guiarán paso a paso en cómo convertirte <strong>mejor</strong>, más <strong>fuerte</strong> y <strong>preparado</strong> para tu disciplina deportiva. </p>
                    </Description>
                </div>
            </div>
        </Section>
    );
}

export default Welcome;