import { SIZE } from "../mediaquery";

export const scrollToSection = e => {
    e.preventDefault();

    const _target = e.target
    let _intViewportWidth = window.innerWidth

    if (_intViewportWidth <= `${SIZE.md}`) delayBeforeScroll(_target)
    else setScrollPosition(_target)
}

export const delayBeforeScroll = _target => {

    const _timeout = setTimeout(() => {
        setScrollPosition(_target)
        clearTimeout(_timeout);
    }, 500) 
}

export const setScrollPosition = _target => {
    let _id = _target.getAttribute('data-id');
    let _element = document.querySelector(`#${_id}`);
    let _elementPosition = _element.offsetTop;
    let _header = document.querySelector('header')
    let _heightHeader = _header.offsetHeight;
    let _scrollToPosition = _elementPosition - _heightHeader;

    window.scrollTo({
        top: _scrollToPosition,
        behavior: 'smooth'
    });
}