export const FAMILY = {
    sans       : 'Open Sans, sans-serif',
    raleway    : 'Raleway, sans-serif',
    staatliches: 'Staatliches, cursive',

    primary  : 'Staatliches, cursive',
    secondary: 'Raleway, sans-serif',
    body     : 'Open Sans, sans-serif',
}

export const COLOR = {
    white: '#fff',
    black: '#000',

    primary: '#ff0000',
    secondary: '#000',
    tertiary: '#fff',

    blackmedium: '#474747',

    greylight: '#f5f6f8',
    greymedium: '#E8E7E7',
    greybold: '#D9D6D6'
}

export const WEIGHT = {
    light    : 300,
    normal   : 400,
    semibold : 500,
    bold     : 700,
    extrabold: 800,
}

export const ZINDEX = {
    base   : 0,
    up     : 1,
    upper  : 2,
    down   : -1,
    downer: -2,
}

export const HEIGHT = {
    header: '117px',
    headersticky: '70px',
    footer: '50px'
}

export const PADDING = {
    yheaderxs: '15px',
    yheadermd: '35px',
    colbootstrap: '15px',
}

export const MARGIN = {
    h2bottompx: '20px',
    h2bottomvw: '2.5vw'
}

