import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useStaticQuery, graphql } from 'gatsby';
import { scrollToSection } from '../../utils/scrollToSection';
import { Container, Wrapper, Title, Description, Button, Url, Img } from './CarouselHeader.css.js';

const CarouselHeader = () => {

    const images = useStaticQuery(graphql`
        query {
            imgcarousel01: file(relativePath: {eq: "img-carousel-01.jpg"}) {
                publicURL
            },
            imgcarousel02: file(relativePath: {eq: "img-carousel-02.jpg"}) {
                publicURL
            },
            imgcarousel03: file(relativePath: {eq: "img-carousel-03.jpg"}) {
                publicURL
            }
        }
    `);

    const carouselitems = [
        {
            image: images.imgcarousel01.publicURL,
            title: 'Que el Covid no te detenga',
            description: 'Planes de entrenamiento outdoor adaptados a tus objetivos',
            button: 'Infórmate',
            id: 'tarifas'
        },
        {
            image: images.imgcarousel02.publicURL,
            title: 'Visita nuestro canal de Youtube',
            description: 'Ejercicios rápidos y prácticos para cualquier nivel',
            button: 'Ver vídeos',
            url: 'https://www.youtube.com/channel/UCRDI6_X7rOeNSuCc946LlGg'
        },
        {
            image: images.imgcarousel03.publicURL,
            title: 'Entrena a tu ritmo',
            description: `Donde quieras y cuando quieras, desde tu móvil o ordenador.
            Libérate de los horarios del gimnasio!`,
            id: 'contact'
        }
    ];


    return (
        <Container id="home">
            <Carousel>
                {
                    carouselitems.map((item, i) => {
                        return (
                            <Carousel.Item key={i} interval={6000}>
                                <Wrapper href="#">
                                    <picture>
                                        <source srcSet={item.image} />
                                        <Img srcSet={item.image} alt="" className="d-block img-fluid" />
                                    </picture>
                                    <Carousel.Caption>
                                        <div>
                                            <Title>{item.title}</Title>
                                            <Description>{item.description}</Description>
                                            { item.id 
                                                ?   
                                                item.button 
                                                    ?
                                                        <Button 
                                                            data-id={item.id}
                                                            className="btn btn-sm btn-outline-secondary"
                                                            onClick={scrollToSection}
                                                        >{ item.button }
                                                        </Button>
                                                    :
                                                        null
                                                :   
                                                    <Url 
                                                        href={item.url}
                                                        className="btn btn-sm btn-outline-secondary"
                                                        target="_blank"
                                                    >{ item.button ? item.button : null }
                                                    </Url>
                                            }
                                        </div>
                                    </Carousel.Caption>
                                </Wrapper>
                            </Carousel.Item>
                        );
                    })
                }
            </Carousel>
        </Container>
    );
}
 
export default CarouselHeader;