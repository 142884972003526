import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Container, Description, Title, Img } from './About.css.js';

const About = () => {

    const QUERY = useStaticQuery(graphql`
        query {
            image: file(relativePath: {eq: "img-sobre-mi.jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <Section id="sobre_mi">
            <Title>Sobre <span>MÍ</span></Title>
            <Container className="container-fluid">
                <div className="row">
                    <Description className="col-lg-7">
                        <p><strong>Licenciado en Ciencias de la actividad física y el deporte</strong> con más de <strong>15 años de experiencia</strong> en actividad deportiva, con diferentes titulaciones oficiales: socorrista acuático, técnico de natación de nivel 1, técnico de animación y educación física y docente.
                        </p>

                        <p>Siempre fui un niño que le apasionaba ver el Tour de France y los JJOO, imitando las proezas de sus estrellas al caer la tarde. Tras años practicando deporte y compitiendo, decidí enfocar toda esa energía en algo realmente satisfactorio que no fuese sólo entrenar. Entendí que debía formarme y aplicar los conocimientos para conseguir un <strong>rendimiento óptimo</strong>.</p> 
                        
                        <p>Decidí estudiar recibiendo una comprensión completa del <strong>cuerpo humano</strong> y su increíble <strong>adaptación al esfuerzo</strong>. De cómo un plan de entrenamiento con rutinas dilatadas en ciclos durante meses, se conseguía llegar a aquellos límites a los que antes no era capaz.</p>

                        <p>Al acabar la licenciatura pude comenzar a aplicar todas esas competencias y lo que más me gustaba: como <strong>entrenador profesional</strong>. Con los años abrí una nueva puerta a mi carrera profesional al decidir dar un paso como <strong>docente</strong> para futuros preparadores físicos.</p>

                        <p>Si buscas desarrollarte como deportista enfocándote en la <strong>optimización de los entrenamientos</strong>, no lo dudes, contacta con nosotros.</p>
                    </Description>
                    <div className="col-lg-5">
                        <Img src={QUERY.image.publicURL} alt="" />
                    </div>
                </div>
            </Container>
        </Section>
    );
}
 
export default About;