export const SIZE = {
    xs: 0,
    md: 991,
    lg: 992
};

export const DEVICE = {
    portable: `(max-width: ${SIZE.md}px)`,
    desktop: `(min-width: ${SIZE.lg}px)`
};


  
  