import { DEVICE } from '../../mediaquery';
import styled from '@emotion/styled';

const gutpxmax = '5px';
const gutpxmin = '2.5px';
const gutvw = '1vw';

export const List = styled.ul`
    list-style-type: none;
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-top: -6px;
    margin-left: clamp(-${gutpxmin}, -${gutvw}, -${gutpxmax});
    margin-right: clamp(-${gutpxmin}, -${gutvw}, -${gutpxmax});
    margin-bottom: 0;
    padding: 0;

    @media ${DEVICE.desktop} {
        width: auto;
    }

    &.footer {
        display: flex;
        width: auto;
        margin-top: 5px;
    }
`

export const ListItem = styled.li`
    padding-left: clamp(${gutpxmin}, ${gutvw}, ${gutpxmax});
    padding-right: clamp(${gutpxmin}, ${gutvw}, ${gutpxmax});
`

export const ListAnchor = styled.a`
    font-size: 0;
`

export const ListImage = styled.img`
    width: clamp(13px, 6vw, 20px);
    height: clamp(13px, 6vw, 20px);

    &.footer {
        width: 20px;
        height: 20px;
    }
`